"use client";

import type { ReactNode } from "react";
import {
  AssistantRuntimeProvider,
  CoreMessage,
  TextContentPart,
  useLocalRuntime,
  type ChatModelAdapter,
} from "@assistant-ui/react";
import { createContext, useContext } from "react";

const MyModelAdapter: ChatModelAdapter = {
  async run({ messages, abortSignal }) {
    // Extract all messages in the chat.
    const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

    // Format the messages array to include only the relevant parts for the backend.
    const formattedMessages = messages.map((message) => ({
      role: message.role, // 'user' or 'assistant'
      content: (message.content[0] as TextContentPart).text,
    }));

    // Get the most recent user message
    const mostRecentMsg = formattedMessages[formattedMessages.length - 1].content;

    const result = await fetch(`${apiBaseUrl}/api/query`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // Forward the formatted messages in the chat to the API
      body: JSON.stringify({
        query: mostRecentMsg,
        messages: formattedMessages,
      }),
      credentials: "include",
      // If the user hits the "cancel" button or escape keyboard key, cancel the request
      signal: abortSignal,
    });

    if (!result.ok) {
      return {
        content: [
          {
            type: "text",
            text: "Unable to answer this query at this time. Please try a different query."
          }
        ]
      }
    }

    const data = await result.json();

    if ("plaintext" in data) {
      return {
        content: [
          {
            type: "text",
            text: data.plaintext,
          }
        ]
      }
    }

    return {
      content: [
        {
          type: "text",
          text: data.summary ?? "",
        },
        {
          type: "tool-call",
          toolCallId: "placeholder",
          toolName: "display_data",
          args: {}, // This needs to be here to satisfy TypeScript linter.
          result: JSON.stringify(data.table),
          argsText: mostRecentMsg, // This needs to be here to satisfy TypeScript linter.
        },
      ],
    };
  },
};

const RuntimeContext = createContext<{
  resetMessages: (messages: CoreMessage[]) => void;
} | null>(null);

// Export the context for usage in other components
export function useRuntime() {
  return useContext(RuntimeContext);
}
 
export function MyRuntimeProvider({
  children,
}: Readonly<{
  children: ReactNode;
}>) {
  const runtime = useLocalRuntime(MyModelAdapter);

  // Expose a function to reset messages
  const resetMessages = (messages: CoreMessage[]) => {
    runtime.reset({ initialMessages: messages });
  };

  return (
    <RuntimeContext.Provider value={{ resetMessages }}>
      <AssistantRuntimeProvider runtime={runtime}>
        {children}
      </AssistantRuntimeProvider>
    </RuntimeContext.Provider>
  );
}