import React, { useState } from "react";
import { Button, message, Modal, Spin } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import {
  CoreMessage,
  useThread,
  TextContentPart,
  ThreadMessage,
  CoreUserContentPart,
  CoreAssistantContentPart,
} from "@assistant-ui/react";

function mapThreadMessagesToCoreMessages(threadMessages: ThreadMessage[]): CoreMessage[] {
  return threadMessages.map(threadMessage => {
    switch (threadMessage.role) {
      case "system":
        return {
          role: "system",
          content: threadMessage.content as [TextContentPart],
        };
      case "user":
        return {
          role: "user",
          content: threadMessage.content.map(({ type, ...rest }) => ({
            type,
            ...rest,
          })) as CoreUserContentPart[],
        };
      case "assistant":
        return {
          role: "assistant",
          content: threadMessage.content.map(({ type, ...rest }) => ({
            type,
            ...rest,
          })) as CoreAssistantContentPart[],
        };
      default:
        throw new Error("Invalid ThreadMessage role");
    }
  });
}

const ShareButton = () => {
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const thread = useThread();

  async function createSharedConversation(messages: CoreMessage[]) {
    const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

    const response = await fetch(`${apiBaseUrl}/api/share`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ messages }),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Failed to share conversation");
    }

    const data = await response.json();
    return data.share_token;
  }

  const handleShare = async () => {
    setIsModalVisible(true);
    setIsLoading(true);

    try {
      const coreMessages = mapThreadMessagesToCoreMessages(Array.from(thread.messages));
      const shareToken = await createSharedConversation(coreMessages);
      const url = `${window.location.origin}/share/${shareToken}`;
      setShareUrl(url);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      message.error("Failed to copy URL.");
    }
  };

  const getLink = () => {
    if (shareUrl) {
      if (navigator.clipboard && window.isSecureContext) {
        // Modern clipboard API
        navigator.clipboard.writeText(shareUrl)
          .then(() => messageApi.success("Link copied to clipboard."))
          .catch((e) => {
            console.log(e);
      });
      } 
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {contextHolder}
      <Button type="default" icon={<ShareAltOutlined />} onClick={handleShare}>
        Share
      </Button>

      <Modal
        open={isModalVisible}
        title="Sharing Your Conversation"
        onCancel={handleCloseModal}
        footer={null}
        closable={false}
        centered
      >
        <div style={{ textAlign: "center" }}>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <p>Any messages you add after sharing stay private.</p>
              <Button
                className="create-link-button"
                type="primary"
                onClick={getLink}
                style={{marginTop: 8}}
              >
                Create Link
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ShareButton;
