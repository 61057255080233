import React, { useState, useEffect } from 'react';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { Button, Modal, message } from 'antd';

import "./ProfileButton.css";

const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const ProfileButton = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Handle checking if a stored token exists and setting user details
  useEffect(() => {
    const storedToken = Cookies.get('google_token');
    if (storedToken) {
      // If token exists in cookies, use it to fetch user details
      setIsLoggedIn(true);
    }
  }, []); // Run only once on mount

  const handleLoginSuccess = (response) => {
    // Send the Google token to the backend (Flask server) for validation
    const { credential } = response;
    Cookies.set('google_token', credential, { expires: 365 });
    // Decode the credential to get user info (you might use JWT decoding here)
    // const userObject = JSON.parse(atob(credential.split('.')[1]));
    setIsLoggedIn(true);
    setIsModalVisible(false);

    // Send token to backend to authenticate. 
    fetch(`${API_BASE_URL}/auth/google`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: "include",
      body: JSON.stringify({ token: credential }),
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error('Error:', err));
  };

  const handleLogout = () => {
    googleLogout();
    fetch(`${API_BASE_URL}/auth/logout`, {
      method: 'POST',
      credentials: 'include'
    })
    .then((res) => res.json())
    .then(() => message.success('Logged out successfully'))
    .catch((err) => console.error('Error logging out:', err));
    
    setIsLoggedIn(false);
    Cookies.remove('google_token');
  };

  return (
    <div className="profile">
      {isLoggedIn ? (
        <Button type="primary" onClick={handleLogout}>Logout</Button>
      ) : (
        <>
          <Button type="default" onClick={() => setIsModalVisible(true)}>Log in</Button>
          <Modal
            title="Log in with Google"
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            centered
          >
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <GoogleOAuthProvider clientId="189951337231-qrdqv4gnt1lngbeitttsiuo8hk0cqr6e.apps.googleusercontent.com">
                <GoogleLogin onSuccess={handleLoginSuccess} onError={() => message.error('Login Failed')} />
              </GoogleOAuthProvider>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};
