"use client"

import "./globals.css";
import "./layout.css"; // Import the layout-specific CSS


import { cn } from "@/lib/utils";
import { Montserrat } from "next/font/google";
import { MyRuntimeProvider } from "./MyRuntimeProvider";
import { ProfileButton } from "@/components/ProfileButton";
import { HelpButton } from "@/components/HelpButton";
import  ShareButton  from "@/components/tools/ShareButton";
import Link from "next/link";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/image";

const montserrat = Montserrat({ subsets: ["latin"] });

// Styled MUI Badge
const BetaBadge = styled(Badge)({
  ".MuiBadge-badge": {
    backgroundColor: "#1976d2", // MUI Primary Blue
    color: "white",
    fontSize: "0.75rem",
    borderRadius: "16px",
    textTransform: "uppercase",
  },
});

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <MyRuntimeProvider>
      <html lang="en">
        <title>CoverBoy</title>
        <body className={cn(montserrat.className, "h-dvh")}>

          <div className="layout-container">
            {/* Sticky Header */}
            <header className="header">
            <Link href="/" className="header-title-link">
              <h1 className="header-title" style={{ display: 'flex', alignItems: 'center' }}>
                <Image className="logo" src="/favicon.ico" alt="CoverBoy Logo" width={48} height={48} /> 
                <span className="app-title">
                  CoverBoy
                </span>
                <span>
                  <BetaBadge badgeContent="Beta" />
                </span>
              </h1>
            </Link>
              <div className="header-right">
                <div className="share-button">
                  <ShareButton></ShareButton>
                </div>
                <div className="profile-button">
                  <ProfileButton></ProfileButton>
                </div>
                <div className="help-button">
                  <HelpButton></HelpButton>
                </div>
              </div>
            </header>

            {/* Page Content and Chatbot */}
            <main className="content">{children}</main>
          </div>
        </body>
      </html>
    </MyRuntimeProvider>
  );
}